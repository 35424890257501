import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link, useNavigate } from "react-router-dom";
import { createAPIPurchaseOrder, Endpoint } from "../../Services/PurchaseOrder/PurchaseOrderService";
import storage from "../../Services/Storage";
import { useEffect } from "react";
import { format, subDays } from "date-fns";
import { useState } from "react";
import * as Fa from "react-icons/fa";
import { toast } from "react-toastify";

const AllRecordsPO = () => {
    const currentDate = format(new Date(), "yyyy-MM-dd");
    const date = subDays(new Date(), 7);
    const sevenDays = format(date, "yyyy-MM-dd");
    const nav = useNavigate();
    const [fromDate, setFromDate] = useState(sevenDays);
    const [toDate, setToDate] = useState(currentDate);
    const [allRecord, setAllRecord] = useState([]);
    const auth = storage.getLocal("user");
    const GetAllPO = async () => {
        try{
            const fDate = new Date(fromDate);
            const fromDateRange = format(fDate, "dd/MM/yyyy");
            const tDate = new Date(toDate);
            const toDateRange = format(tDate, "dd/MM/yyyy");
            let from_date = format(fDate, "yyyy/MM/dd");
            let to_date = format(tDate, "yyyy/MM/dd");
            if (from_date > to_date) {
                toast.warn("From Date should be equal to or less than To Date", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 4000,
                });
            }
            const res = await createAPIPurchaseOrder().get(`${Endpoint.GetAllPO}?CompId=${auth.CompId}&fromDate=${fromDateRange}&toDate=${toDateRange}`);
            console.log(res,"GetAllPO");
            setAllRecord(res.data.Data);
        }catch(err){
            console.log(err);
        }
    };
    const renderAction = (rowdata) => {
        return (
            <>
                <div
                    className="d-inline"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Cancel"
                >
                    <button className="grid-icon" onClick={()=>nav(`/purchasemanagement/createpurchaseorder?PO_ID=${rowdata.PO_HDR_ID}&PO_DATE=${rowdata.OrderDate}`)}>
                        <Fa.FaEye  />
                    </button>
                </div>
            </>
        );
    };
    useEffect(()=>{
        GetAllPO();
    },[]);
    return (
        <main>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3 className="py-2">Purchase Order</h3>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="float-end mb-2">
                        <Link to="/purchasemanagement/createpurchaseorder?PO_ID=&PO_DATE=">
                            <button type="button" className="btn btn-primary">
                            Create Purchase Order
                            </button>
                        </Link>
                    </div>
                </div>
            
                <section className="sales-box">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="mb-3">
                                <label className="form-label">From Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    defaultValue={sevenDays}
                                    max={currentDate}
                                    min="2010-01-01"
                                    name="date-field"
                                    onChange={(e) => {
                                        setFromDate(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="mb-3">
                                <label className="form-label">To Date</label>
                                <input
                                    type="date"
                                    defaultValue={currentDate}
                                    max={currentDate}
                                    // min={fromDate}
                                    className="form-control"
                                    name="date-field"
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 pt-1">
                            <div className="float-end mt-4">
                                <button type="button" className="btn btn-primary">Get Purchase Order</button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mt-3 pb-3">
                    <div className="DataTable">
                        <DataTable
                            value={allRecord}
                            paginator
                            rows={10}
                            rowsPerPageOptions={[10, 25, 50]}
                            scrollHeight="80vh"
                            scrollable
                            tableStyle={{ minWidth: "60rem" }}
                            filterDisplay="row"
                            showGridlines
                            stripedRows
                        >
                            <Column field="OrderDate" header="Purchase Order Date" filterMatchMode="contains" filter filterPlaceholder="Search Here..." style={{ minWidth: "5rem" }} />
                            <Column field="OrderNumber" header="Purchase Order Number" filterMatchMode="contains" filter filterPlaceholder="Search Here..." style={{ minWidth: "8rem" }} />
                            <Column field="Status" header="Status" filterMatchMode="contains" filter filterPlaceholder="Search Here..." style={{ minWidth: "8rem" }} />
                            <Column body={renderAction} header="Action" style={{ minWidth: "4rem" }} />
                        </DataTable>
                    </div>
                </section>
            </div>
        </main>
    );
};

export default AllRecordsPO;