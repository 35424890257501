import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import * as Fa from "react-icons/fa";
import { Link, useSearchParams } from "react-router-dom";
import { AutoComplete } from "primereact/autocomplete";
import { format } from "date-fns";
import { createAPIPurchaseOrder, Endpoint } from "../../Services/PurchaseOrder/PurchaseOrderService";
import storage from "../../Services/Storage";
import Loader from "../../Components/Loader";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";

const CreatePO = () => {
    const [loader,setLoader] = useState(false);
    const [compCustCode, setCompCustCode] = useState();
    const [prodList, setProdList] = useState([]);
    const [prodVal, setProdVal] = useState(null);
    const [prodData, setProdData] = useState(null);
    const [purchaseQty, setPurchaseQty] = useState("");
    const [poRecord, setPoRecord] = useState([]);
    const [confReset, setConfReset] = useState(false);
    const [poNo, setPoNo] = useState("");
    const [poDate, setPoDate] = useState(format(new Date(), "yyyy-MM-dd"));
    const [disableField, setDisableField] = useState(false);
    const [btnVisible, setBtnVisible] = useState(true);

    const [searchParam] = useSearchParams();
    let PO_ID = searchParam.get("PO_ID");
    let PO_DATE = searchParam.get("PO_DATE");

    const GetAllPO = async () => {
        try{
            const res = await createAPIPurchaseOrder().get(`${Endpoint.GetAllPO}?CompId=${auth.CompId}&fromDate=${PO_DATE}&toDate=${PO_DATE}`);
            const filterData = res.data.Data.filter((a)=>{return(a.PO_HDR_ID===PO_ID);});
            console.log(filterData,"GetAllPO");
            setPoNo(filterData[0].OrderNumber);
            const [day, month, year] = filterData[0].OrderDate.split("/");
            const formattedDate = `${year}-${month}-${day}`;
            setPoDate(formattedDate);
        }catch(err){
            console.log(err);
        }
    };
    const GetAllPOProdDetl = async () => {
        try{
            const res = await createAPIPurchaseOrder().get(`${Endpoint.GetAllPOProdDetl}?PO_HDR_ID=${PO_ID}`);
            setPoRecord(res.data.Data);
        }
        catch(err){
            console.log(err);
        }
    }; 
    const auth = storage.getLocal("user");
    const onConfirmResetClick = () => {
        setProdVal([]);
        setPurchaseQty("");
        setPoRecord([]);
        setConfReset(false);
        setDisableField(false);
        setPoNo("");
    };
    const GetCompCustCode = async () => {
        setLoader(true);
        createAPIPurchaseOrder()
            .get(`${Endpoint.GetCompCustCode}?CompId=${auth.CompId}`)
            .then((res)=>{
                setCompCustCode(res.data.Data[0]);
                setLoader(false);
            })
            .catch((err)=>{
                setLoader(false);
                console.log(err);
            });
    };
    const GetProd = async () => {
        setLoader(true);
        createAPIPurchaseOrder()
            .get(`${Endpoint.ProdList}?CompId=${auth.CompId}`)
            .then((res)=>{
                console.log(res.data.Data,"GetProd");
                setProdList(res.data.Data);
                setLoader(false);
            })
            .catch((err)=>{
                setLoader(false);
                console.log(err);
            });
    };
    const searchProd = (event) => {
        setTimeout(() => {
            let filterProd;
            
            if (!event.query.length) {
                filterProd = [...prodList];
            } else {
                filterProd = prodList.filter((prod) => {
                    return prod.PROD_DESC.toLowerCase().includes(
                        event.query.toLowerCase())||prod.PROD_ID.toLowerCase().includes(
                        event.query.toLowerCase()
                    );
                });
            }
    
            setProdData(filterProd);
        }, 1000);
    };
    const ProdTemplate = (item) => {
        return(<div>{item.PROD_ID} - {item.PROD_DESC}</div>);
    };
    const addtoCart = () => {
        setLoader(true);
        try{
            if(prodVal===null||prodVal.PROD_ID===undefined){
                toast.warning("Please select product", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 8000,
                });
                setLoader(false);
                return;
            }
            if(purchaseQty===undefined||purchaseQty.toString()==="0"||purchaseQty.toString()===""){
                toast.warning("Purchase quantity can not be empty or zero", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 8000,
                });
                setLoader(false);
                return;
            }
            if(poRecord.length>0){
                const filterData = poRecord.filter((a)=>{return(a.PROD_ID===prodVal.PROD_ID);});
                if(filterData.length>0){
                    toast.warning("Product already added", {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 8000,
                    });
                    setLoader(false);
                    return;
                }
            }
            console.log(prodVal,purchaseQty,"addtoCart");
            let obj = {
                SR_NO : poRecord.length+1,
                PROD_ID : prodVal.PROD_ID,
                PROD_DESC : prodVal.PROD_DESC,
                PO_QTY : purchaseQty
            };
            setPoRecord((current) => [...current, obj]);
            setProdVal([]);
            setPurchaseQty("");
            setLoader(false);
        }catch(ex){
            console.log(ex,"addtoCart");
            setLoader(false);
        }
    };
    const blockInvalidChar = (e) => {
        [".", "+", "-", "e", "E"].includes(e.key) && e.preventDefault();
    };
    const renderAction = (rowdata) => {
        return (
            <>
                <div
                    className="d-inline"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Cancel"
                >
                    <button className="grid-icon" onClick={()=>onRemoveRow(rowdata)}>
                        <Fa.FaTimes className="cancel" />
                    </button>
                </div>
            </>
        );
    };
    const onRemoveRow = (e) => {
        setLoader(true);
        try{
            const filterData = poRecord.filter((a)=>{return(a.PROD_ID!==e.PROD_ID);});
            console.log(filterData,"onRemoveRow");
            setPoRecord(filterData);
            setLoader(false);
        }catch(ex){
            console.log(ex,"onRemoveRow");
            setLoader(false);
        }
    };
    const onSaveClick = async() => {
        setLoader(true);
        let date = new Date();
        let day = String(date.getDate()).padStart(2, "0");
        let month = String(date.getMonth() + 1).padStart(2, "0");
        let year = date.getFullYear();
        const currentDate = `${day}/${month}/${year}`.toString();
        
        let _orderlines = poRecord.map((a,i)=>({
            orderlineID : (i+1).toString(),
            productCode : a.PROD_ID,
            orderQuantity : a.PO_QTY
        }));
        let _objSaveJSON = {
            orderID : "",
            distributorCode : compCustCode.DIST_ID,
            partyCode : compCustCode.CUST_CODE,
            orderNumber : "",
            orderDate : currentDate.toString(),
            orderPaymentTerm : "",
            orderRemarks : "",
            orderlines:_orderlines
        };
        let obj = {
            compId : auth.CompId,
            objSaveJSON : _objSaveJSON,
        };
        console.log(obj,"onSaveClick");
        // return;
        try{
            const res = await createAPIPurchaseOrder().post(`${Endpoint.SavePO}`,JSON.stringify(obj));
            console.log(res,"save");
            if(res.data.Status === "Success"){
                toast.success("Data Saved Successfully", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 8000,
                });
                let PONo = res.data.POHD_ID.split("|");
                setPoNo(PONo[1]);
                setDisableField(true);
                setLoader(false);
            }
        }
        catch(err){
            toast.error(err?.response?.data?.Message, {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 8000,
            });
            console.log(err,"save");
            setLoader(false);
        }
    };
    useEffect(()=>{
        if(PO_ID===""){
            GetProd();
            GetCompCustCode();
        }
        else if(PO_ID!==""){
            setDisableField(true);
            GetAllPO();
            GetAllPOProdDetl();
            setBtnVisible(false);
        }
    },[]);
    if(loader){
        return(<Loader/>);
    }
    else{
        return (
            <main>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h3 className="py-2">Create Purchase Order</h3>
                    </div>
                    <div className="col-lg-6 col-md-7 col-sm-12">
                        <div className="float-end">
                            <Link to="/purchasemanagement/purchaseorder">
                                <button type="button" className="btn btn-outline-primary">
                                    <Fa.FaLongArrowAltLeft className="backIcon" /> Back to All
                    Records
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <section className="sales-box">
                    <div className="row d-flex justify-content-end align-items-end">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="mb-3">
                                <label className="form-label">Purchase Order No</label>
                                <div className="d-block">
                                    <input className="form-control" value={poNo} disabled/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="mb-3">
                                <label className="form-label">Purchase Order Date</label>
                                <div className="d-block">
                                    <input type="Date" className="form-control" value={poDate} disabled/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12">
                            <label className="form-label">
                  Product <span className="mandatory">*</span>
                            </label>
                            <div className="d-block">
                                <AutoComplete dropdown
                                    field="PROD_DESC"
                                    value={prodVal}
                                    onChange={(e)=>setProdVal(e.value)}
                                    completeMethod={searchProd}
                                    suggestions={prodData}
                                    placeholder="Select a product"
                                    itemTemplate={ProdTemplate}
                                    disabled={disableField}
                                />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">
                            <label className="form-label">Purchase Quantity</label>
                            <input className="form-control" value={purchaseQty} type="number" disabled={disableField} onKeyDown={blockInvalidChar} onChange={(e)=>{
                                if(e.target.value.toString().startsWith("-")){
                                    toast.warning("Purchase order quantity should not be negative value", {
                                        position: toast.POSITION.TOP_CENTER,
                                        pauseOnFocusLoss: false,
                                        closeButton: false,
                                        autoClose: 8000,
                                    });
                                    setPurchaseQty("");
                                }else{
                                    setPurchaseQty(parseInt(e.target.value.replace(/^(-?)0+/, "$1"), 10));
                                }}}
                            />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            {btnVisible&&<div className="float-end">
                                <button type="button" className="btn btn-outline-primary me-3" disabled={poRecord.length==0?true:false} onClick={onSaveClick}>
                    Save
                                </button>
                                <button type="button" className="btn btn-primary me-3" onClick={()=>addtoCart()}>
                    Add to Cart
                                </button>
                                <button type="button" className="btn btn-cancel-red" onClick={()=>setConfReset(true)}>
                    Reset
                                </button>
                            </div>}
                        </div>
                    </div>
                </section>
                {/* <section className="sales-box">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row order-summery">
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    <h3> Purchase Order Summary</h3>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className=" order-detail-container">
                                        <div className="order-box1">Net Volume (Ltr)</div>
                                        <div className="order-box text-end">
                                            <span>1</span>
                                        </div>
                                    </div>
                                    <div className=" order-detail-container">
                                        <div className="order-box1">Net Weight (kg):</div>
                                        <div className="order-box text-end">
                                            <span>1</span>
                                        </div>
                                    </div>
                                    <hr className="borderMobileView"></hr>
                                    <div className=" order-detail-container">
                                        <div className="order-box1">Gross Value:</div>
                                        <div className="order-box text-end">
                                            <span>₹ 0.00</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="order-detail-container">
                                        <div className="order-box">Amount Before Tax:</div>
                                        <div className="order-box text-end">
                                            <span>₹ 0.00</span>
                                        </div>
                                    </div>
                                    <div className="order-detail-container border-bottom">
                                        <div className="order-box">GST Amount:</div>
                                        <div className="order-box text-end">
                                            <span>₹ 0.00</span>
                                        </div>
                                    </div>
                                    <div className="total order-detail-container">
                                        <div className="order-box ">Estimated Total Amount: </div>
                                        <div className="order-box text-end">
                                            <span className="">₹ 0.00</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <div className="DataTable mb-4">
                    <DataTable
                        value={poRecord}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 25, 50]}
                        scrollHeight="80vh"
                        scrollable
                        tableStyle={{ minWidth: "60rem" }}
                        filterDisplay="row"
                        showGridlines
                        stripedRows
                    >
                        <Column field="SR_NO" header="Serial No" style={{ minWidth: "5rem" }} />
                        <Column field="PROD_ID" header="Product Code" style={{ minWidth: "8rem" }} />
                        <Column field="PROD_DESC" header="Product Name" style={{ minWidth: "4rem" }} />
                        <Column
                            field="PO_QTY"
                            header="Purchase Quantity"
                            style={{ minWidth: "4rem" }}
                        />
                        <Column body={renderAction} header="Action" style={{ minWidth: "4rem" }} />
                    </DataTable>
                </div>
                <Dialog
                    header="Confirmation"
                    visible={confReset}
                    style={{ width: "80vw" }}
                    onHide={() => setConfReset(false)}
                    closable={false}
                    resizable={false}
                    draggable={false}
                >
                    <div className="col-md-12 col-lg-12 col-sm-12">
                        Are you sure to reset your changes?
                    </div>
                    <div className="float-end">
                        <button
                            type="button"
                            className="btn btn-primary me-3"
                            onClick={onConfirmResetClick}
                        >
                            Reset
                        </button>
                        <button
                            type="button"
                            className="btn btn-cancel-red"
                            onClick={()=>setConfReset(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </Dialog>
            </main>
        );
    }
    
};

export default CreatePO;
