import Axios from "../../api/Axios";
const BaseUrl = "PO/";
export const Endpoint = {
    ProdList : "ProdList",
    SavePO : "SavePO",
    GetCompCustCode : "GetCompCustCode",
    GeneratePO_NO : "GeneratePO_NO",
    GetAllPO : "GetAllPO",
    GetAllPOProdDetl : "GetAllPOProdDetl"
};

export const createAPIPurchaseOrder = () => {
    return { 
        get: (Endpoint) => Axios.get(BaseUrl + Endpoint),
        post: (Endpoint, data) => Axios.post(BaseUrl + Endpoint, data),
    };
};